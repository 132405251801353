/**
 * Ein Service, der einen einfachen "Hello World!"-Text zurückgibt
 */
class HelloService {
    /**
     * Holt den "Hello World!"-Text
     * @returns {Promise<string>} Ein Promise, das den Text "Hello World!" enthält
     */
    static async getHelloWorld() {
      return "Hello World!";
    }
}
  
export default HelloService;
  