import React from "react";
import HelloService from "./HelloService";
import "./index.css"
import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      message: "",
      showHello: false,
      showButton: true,
      animateBg: false,
      animateText: false,
    };
  }

  // Handler for button click
  handleClick = () => {
    // Retrieve message from HelloService
    HelloService.getHelloWorld().then((response) => {
      // Update state to show message and trigger animations
      this.setState({
        message: response,
        showHello: true,
        showButton: false,
        animateBg: true,
        animateText: true,
      });
    });
  };

  render() {
    return (
      <div className={`container ${this.state.animateBg ? "animate animateBg" : ""}`}>
        {/* Show button if it hasn't been clicked yet */}
        {this.state.showButton && (
          <div className="centered">
            <button className="button-57" onClick={this.handleClick}><span className="text">Welcome!</span><span>Initialize...</span></button>
          </div>
        )}
        {/* Show hello message if button has been clicked */}
        {this.state.showHello && (
          <div
            className={`centered ${this.state.animateText ? "animate animateText" : ""}`}
          >
            <h1>{this.state.message}</h1>
          </div>
        )}
      </div>
    );
  }
}

export default App;
